
  export default function Usecases() {
    return (
      <div className="bg-white py-24 sm:py-32" id="usecases">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Use Cases</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Entrepreneurs, Investors, and Agencies.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-4">
              <div className="relative overflow-hidden rounded-3xl  px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                <img
                  className="absolute inset-0 h-full w-full "
                  src="./entrepreneur.svg"
                  alt=""
                />
                <div className="absolute inset-0  mix-blend-multiply" />
              </div>
            </div>
            <div>
              <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                {/* <p className="text-base font-semibold leading-7 text-indigo-600">Company values</p> */}
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Entrepreneurs
                </h1>
                <div className="max-w-xl">
                  <p className="mt-6">
                  Better understand the software market and identify promising niches. Understand the pricing in each niche, how fast products in the niche grow, and the team size they require.
                  </p>
                </div>
              </div>

            </div>
          </div>
          <div className=" mt-24 grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className=" order-1 lg:order-none text-base leading-7 text-gray-700 lg:max-w-lg">
              {/* <p className="text-base font-semibold leading-7 text-indigo-600">Company values</p> */}
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Investors
              </h1>
              <div className="max-w-xl">
                <p className="mt-6">
                Identify potential investment or acquisition targets by understanding which software products and categories grow the fastest.
                </p>
              </div>
            </div>
            <div className="lg:pr-4">
              <div className="relative overflow-hidden rounded-3xl  px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                <img
                  className="absolute inset-0 h-full w-full "
                  src="./investors.svg"
                  alt=""
                />
                <div className="absolute inset-0  mix-blend-multiply" />
              </div>
            </div>
          </div>
          <div className="mt-24 grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-4">
              <div className="relative overflow-hidden rounded-3xl  px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                <img
                  className="absolute inset-0 h-full w-full "
                  src="./agencies.svg"
                  alt=""
                />
                <div className="absolute inset-0  mix-blend-multiply" />
              </div>
            </div>
            <div>
              <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                {/* <p className="text-base font-semibold leading-7 text-indigo-600">Company values</p> */}
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Agencies
                </h1>
                <div className="max-w-xl">
                  <p className="mt-6">
                  SEO agencies can identify software products whose web traffic is growing or declining rapidly, and pitch their services to them through the contact data of employees we provide.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
  