import { UserCircleIcon, CurrencyDollarIcon, DocumentChartBarIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Pricing Data',
    description:
      'We provide data on the pricing of the software product, such as the number of pricing plans, pricing for each plan, and description of the plans.',
    href: '#',
    icon: CurrencyDollarIcon,
  },
  {
    name: 'Web Traffic Data',
    description:
      'See which software products are currently growing or declining in web traffic to understand trends in the software market.',
    href: '#',
    icon: DocumentChartBarIcon,
  },
  {
    name: 'Employee Data',
    description:
      'Get access to employee information, such as the number of employees in the company as well as their contact data.',
    href: '#',
    icon: UserCircleIcon,
  },
]

export default function Features() {
  return (
    <div className="bg-white py-24 sm:py-32" id="features">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Features</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            All the data you need
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We have gathered data on thousands of software products, so you don't need to.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <feature.icon className="h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    {/* <a href={feature.href} className="text-sm font-semibold leading-6 text-indigo-600">
                      Learn more <span aria-hidden="true">→</span>
                    </a> */}
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
