import './App.css';
import Header from './Header.js';
import Features from './Features.js';
import Usecases from './Usecases.js';
import Pricing from './Pricing.js';
import FAQs from './FAQs.js';
import Footer from './Footer.js';

function App() {
  return (
    <>
      <Header />
      <Features />
      <Usecases />
      <Pricing />
      <FAQs />
      <Footer />
    </>
  );
}

export default App;
